<template>
  <v-row v-if="!!e._id">
    <v-col
      cols="12"
      md="6"
    >
      <base-edit-dialog-date-time
        v-if="isHome"
        :value="e.startDate"
        :id="e._id"
        label="Beginn"
        @input="({value, id}) => savefield(id, 'startDate', value, 'DateTime')"
      >
        <template #default>
          <v-text-field
            label="Beginn"
            :value="e.startDate | dateformat"
            readonly
          />
        </template>
      </base-edit-dialog-date-time>
      <v-text-field
        v-else
        label="Beginn"
        :value="e.startDate | dateformat"
        disabled
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <base-edit-dialog-hallensuche
        v-if="isHome"
        :value="venue"
        :id="e._id"
        label="Halle"
        allownew
        @input="({value, id}) => savefield(id, 'venue', value, 'UUID')"
      />
      <v-text-field
        v-else
        label="Halle"
        :value="venue"
        disabled
      />
    </v-col>
    <v-col cols="12">
      <base-edit-dialog
        v-if="isHome"
        :value="e.internalComment"
        :id="e._id"
        label="Anmerkungen"
        textarea
        @input="({value, id}) => savefield(id, 'internalComment', value)"
      />
      <v-textarea
        v-else
        label="Anmerkungen"
        :value="e.internalComment"
        disabled
      />
    </v-col>
    <v-col cols="12">
      <base-edit-dialog
        v-if="isMaster"
        :value="e.publicComment"
        :id="e._id"
        label="öffentliche Bemerkungen"
        textarea
        @input="({value, id}) => savefield(id, 'publicComment', value)"
      />
      <v-textarea
        v-else
        label="öffentliche Bemerkungen"
        :value="e.publicComment"
        disabled
      />
    </v-col>
    <v-col
      v-for="t in e.teams"
      :key="t.team._id"
      cols="12"
    >
      <v-switch
        v-model="t.neutralwaiver"
        readonly
        :disabled="!isTeam(t)"
        :label="`Neutralverzicht ${t.team.name}`"
        @click="switchneutral(t)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'org',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    e: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['isMaster']),
    venue () {
      const v = this.e.venue
      return !v ? '' : `${v.name} (${v.address.streetAddress} ${v.address.streetNumber}, ${v.address.postalCode} ${v.address.addressLocality})`
    },
    isHome () {
      return this.isMaster ||
        this.e.teams?.filter(t => t.home)?.reduce((acc, curr) => acc || this.$store.getters.isTeam(curr.team._id), false) ||
        this.e.teams?.filter(t => t.home)?.reduce((acc, curr) => acc || this.$store.getters.isClub(curr.team.club._id), false) ||
        this.e.teams?.filter(t => t.home)?.reduce((acc, curr) => acc || this.$store.getters.isEdv(curr.team._id), false)
    }
  },

  methods: {
    savefield (id, field, value, type) {
      if (!type) type = 'String'
      if (type === 'Int') value = parseInt(value)

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            StbM2021WkUpdate${q}(id: $id, ${field}: $${field}) { _id }
          }
        `,
        variables: {
          id: this.e._id,
          [field]: value
        }
      })
    },
    switchneutral (team) {
      if (!this.isTeam(team)) return

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!, $neutralwaiver: Boolean!) {
            StbM2021WkTeamUpdateNeutralwaiver(id: $id, team: $team, neutralwaiver: $neutralwaiver) { _id }
          }
        `,
        variables: {
          id: this.e._id,
          team: team.team._id,
          neutralwaiver: !team.neutralwaiver
        }
      })
    },
    isTeam (team) {
      return this.isMaster ||
        this.$store.getters.isTeam(team.team._id) ||
        this.$store.getters.isEdv(team.team._id) ||
        this.$store.getters.isClub(team.team.club._id)
    }
  }
}
</script>

<style scoped>

</style>
